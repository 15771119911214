import { Icon } from '@iconify/react'
import styled from 'styled-components'
import CustomButton from './customButton'
import { MicroserviceFilterOption } from './microserviceFilterSelect'
import { SelectedMicroservicesFilters } from './microservicesCardsFilters'

const MicroservicesFiltersTagsPanel = styled.div`
    display: grid;
    grid-template-columns: 1fr 8rem;
    margin-top: 0.75rem;
`

const MicroservicesFiltersTagsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
`

const MicroservicesFilterTag = styled.button`
    border-color: ${(props) => props.theme.color.text.navy};
    border-width: 1px;
    background-color: white;
    color: ${(props) => props.theme.color.text.navy};
    height: 1.5rem;
    border-radius: 0.75rem;
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-left: 0.75rem;
    padding-block: 0;
    transition: all 0.3s ease;

    &:hover {
        color: white;
        background-color: ${(props) => props.theme.color.text.navy};
    }
`

interface ClearMicroservicesFiltersButtonProps {
    onClearFiltersClick: () => void
}

const ClearMicroservicesFiltersButton = ({
    onClearFiltersClick,
}: ClearMicroservicesFiltersButtonProps) => (
    <CustomButton
        text="Clear All Filters"
        icon="close"
        styleType="darkOutline"
        styleOverride={{
            fontSize: 14,
            paddingInline: 0,
            fontWeight: 400,
            display: 'flex',
            justifyContent: 'center',
            paddingBlock: 4,
            height: 24,
            borderWidth: 0,
        }}
        onClick={onClearFiltersClick}
    />
)

export type FilterTagClickHandler = (
    name: keyof SelectedMicroservicesFilters,
    option: MicroserviceFilterOption
) => void

interface MicroservicesFiltersTagsProps {
    selectedFilters: SelectedMicroservicesFilters
    onFilterTagClick: FilterTagClickHandler
    onClearFiltersClick: () => void
}

const MicroservicesFiltersTags = ({
    selectedFilters,
    onFilterTagClick,
    onClearFiltersClick,
}: MicroservicesFiltersTagsProps) => {
    const filtersValues = Object.values(selectedFilters)
    const noFiltersSelected = filtersValues.every(
        (values) => values.length === 0
    )

    if (noFiltersSelected) {
        return null
    }

    const filtersTags = Object.entries(selectedFilters).map(
        ([filterName, selectedFilterOptions]) =>
            selectedFilterOptions.map((option: any) => (
                <MicroservicesFilterTag
                    key={`${filterName}-${option.value}-${option.label}-tag`}
                    onClick={() =>
                        onFilterTagClick(
                            filterName as keyof SelectedMicroservicesFilters,
                            option
                        )
                    }
                >
                    <span>
                        {filterName[0].toUpperCase()}
                        {filterName.slice(1)}: {option.label}
                    </span>
                    <Icon icon="mdi:close" fontSize={16} />
                </MicroservicesFilterTag>
            ))
    )

    return (
        <MicroservicesFiltersTagsPanel>
            <MicroservicesFiltersTagsContainer>
                {filtersTags}
            </MicroservicesFiltersTagsContainer>
            <ClearMicroservicesFiltersButton
                onClearFiltersClick={onClearFiltersClick}
            />
        </MicroservicesFiltersTagsPanel>
    )
}

export default MicroservicesFiltersTags
