import { Icon } from '@iconify/react'
import { Tooltip } from '@mui/material'
import { AnchorHTMLAttributes, MouseEventHandler } from 'react'
import styled from 'styled-components'
import { SelectedMicroservicesFilters } from '../components/microservicesCardsFilters'
import { useAppSelector } from '../config/hooks'
import { LoadingState } from '../models/loadingState'
import {
    microservicesDataSelector,
    microservicesLoadingStateSelector,
} from '../store/microservices.slice'
import { Microservice } from '../types/microservices'
import SpinnerContainer from './spinner'

const MicroserviceCardContainer = styled.div`
    border: 1px solid ${(props) => props.theme.color.border.default};
    min-height: 10rem;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-decoration: unset;

    @media screen and (min-width: 768px) {
        width: calc(50% - 1rem);
    }

    @media screen and (min-width: 1024px) {
        width: calc(33.33% - 1rem);
    }

    @media screen and (min-width: 1600px) {
        width: calc(25% - 1rem);
    }

    box-shadow: 0;
    transition: box-shadow 0.3s;
    &:hover {
        box-shadow: 0 0 0 1px ${(props) => props.theme.color.border.default};
        cursor: pointer;

        & h5 {
            text-decoration: underline;
        }
    }
`

const MicroserviceCardContent = styled.div``

const MicroserviceCardTitle = styled.h5`
    font-size: 1.5rem;
    line-height: 2rem;
    font-family: 'IntelOne Display';
    color: ${(props) => props.theme.color.text.navy};
    margin-left: 1.25rem;
    margin-top: 1rem;
    font-weight: 300;
    margin-bottom: 0;
`

const MicroservicePoweredBy = styled.span`
    display: flex;
    color: ${(props) => props.theme.color.text.navy};
    text-align: left;
    justify-content: left;
    font-family: 'IntelOne Text';
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-inline: 1.25rem;
`

const MicroserviceCardDescription = styled.p`
    color: black;
    margin-inline: 1.25rem;
    font-size: 0.875rem;
    margin-block: 1.5rem 1rem;
`

const MicroserviceCardTags = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.375rem;
    margin-right: 1.25rem;
`

const MicroserviceCardTag = styled.button`
    border: 1px solid ${(props) => props.theme.color.text.navy};
    border-radius: 0.75rem;
    background-color: white;
    color: ${(props) => props.theme.color.text.navy};
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-weight: 400;
    display: flex;
    align-items: center;
    padding-inline: 0.625rem;
    padding-block: 0.125rem;
    user-select: none;

    &.selected {
        color: white;
        background-color: ${(props) => props.theme.color.text.navy};
    }
`

const MicroserviceCPULogos = styled.div`
    margin: 1.25rem;
    margin-left: 2rem;
    float: right;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

const MicroserviceCPULogo = styled.img`
    width: 3rem;
    height: 3rem;
`

const MicroserviceCardFooter = styled.div`
    padding-inline: 1.25rem 1rem;
    padding-block: 1rem;
    display: flex;
    justify-content: space-between;
    margin-top: auto;

    border-top: 1px solid #e2e2e4;

    &:hover {
        cursor: default;
    }
`

const MicroserviceCardActions = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    margin-left: auto;
    margin-top: auto;
`

const CardLinkButton = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.75rem;
    color: black;
    border-radius: 50%;
    width: 2.25rem;
    height: 2.25rem;

    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }
`

const MicroservicesCardsGrid = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 1rem;
    gap: 1rem;
`

const NoMicroservicesFoundMessage = styled.div`
    width: 100%;
    height: calc(100vh / 2);
    min-height: 15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & p {
        font-weight: 300;
        font-family: 'IntelOne Display';
        font-size: 1.75rem;
        margin: 0;

        &:last-child {
            font-size: 1.5rem;
        }
    }
`

type MicroserviceCardLinkButtonDestination = 'dockerhub' | 'github'

const getMicroserviceCardLinkButtonContent = (
    destination: MicroserviceCardLinkButtonDestination
) => {
    let icon = ''
    let tooltipText = ''
    if (destination === 'dockerhub') {
        icon = 'mdi:docker'
        tooltipText = 'Get DockerHub Image'
    } else if (destination === 'github') {
        icon = 'mdi:github'
        tooltipText = 'Check GitHub Repository'
    }
    return { icon, tooltipText }
}

interface MicroserviceCardLinkButtonProps
    extends AnchorHTMLAttributes<HTMLAnchorElement> {
    destination: MicroserviceCardLinkButtonDestination
}

const MicroserviceCardLinkButton = ({
    destination,
    ...props
}: MicroserviceCardLinkButtonProps) => {
    const { icon, tooltipText } =
        getMicroserviceCardLinkButtonContent(destination)

    const tooltipComponentProps = {
        sx: {
            fontFamily: 'IntelOne Display',
            fontWeight: 400,
            backgroundColor: 'black',
            '& .MuiTooltip-arrow': {
                color: 'black',
            },
        },
    }

    return (
        <Tooltip
            title={tooltipText}
            placement="top"
            arrow
            componentsProps={{ tooltip: tooltipComponentProps }}
        >
            <CardLinkButton {...props} target="_blank">
                <Icon icon={icon} />
            </CardLinkButton>
        </Tooltip>
    )
}

interface MicroserviceCardProps extends Microservice {
    selectedFilters: SelectedMicroservicesFilters
    onCardTagClick: (
        filterType: keyof SelectedMicroservicesFilters,
        tag: string
    ) => void
}

const MicroserviceCard = ({
    hardware,
    name,
    description,
    dockerhubHref,
    githubHref,
    type,
    framework,
    serving,
    poweredBy,
    selectedFilters,
    onCardTagClick,
}: MicroserviceCardProps) => {
    const hasSelectedFramework = selectedFilters.framework.some(
        (option) => option.value === framework
    )
    const hasSelectedType = selectedFilters.type.some(
        (option) => option.value === type
    )
    const hasSelectedServing = (serving: string) =>
        selectedFilters.serving.some((option) => serving === option.value)

    const handleCardClick = () => {
        window.open(githubHref, '_blank')
    }
    const handleCardFooterClick: MouseEventHandler = (event) => {
        event.stopPropagation()
    }
    const handleFrameworkTagClick: MouseEventHandler = (event) => {
        event.stopPropagation()
        if (framework) {
            onCardTagClick('framework', framework)
        }
    }
    const handleTypeTagClick: MouseEventHandler = (event) => {
        event.stopPropagation()
        if (type) {
            onCardTagClick('type', type)
        }
    }
    const handleServingTagClick =
        (serving: string): MouseEventHandler =>
        (event) => {
            event.stopPropagation()
            if (serving) {
                onCardTagClick('serving', serving)
            }
        }

    return (
        <MicroserviceCardContainer onClick={handleCardClick}>
            <MicroserviceCardContent>
                <MicroserviceCPULogos>
                    {hardware.includes('Xeon') && (
                        <MicroserviceCPULogo src="/intel-xeon-badge.png" />
                    )}
                    {hardware.includes('Gaudi2') && (
                        <MicroserviceCPULogo src="/intel-gaudi-badge.png" />
                    )}
                </MicroserviceCPULogos>
                <MicroserviceCardTitle>{name}</MicroserviceCardTitle>
                {poweredBy && (
                    <MicroservicePoweredBy>
                        Powered By {poweredBy}
                    </MicroservicePoweredBy>
                )}
                <MicroserviceCardDescription>
                    {description}
                </MicroserviceCardDescription>
            </MicroserviceCardContent>
            <MicroserviceCardFooter onClick={handleCardFooterClick}>
                <MicroserviceCardTags>
                    {framework && (
                        <MicroserviceCardTag
                            className={hasSelectedFramework ? 'selected' : ''}
                            onClick={handleFrameworkTagClick}
                        >
                            {framework}
                        </MicroserviceCardTag>
                    )}
                    {type && (
                        <MicroserviceCardTag
                            className={hasSelectedType ? 'selected' : ''}
                            onClick={handleTypeTagClick}
                        >
                            {type}
                        </MicroserviceCardTag>
                    )}
                    {serving &&
                        serving.map((s) => (
                            <MicroserviceCardTag
                                className={
                                    hasSelectedServing(s) ? 'selected' : ''
                                }
                                onClick={handleServingTagClick(s)}
                            >
                                {s}
                            </MicroserviceCardTag>
                        ))}
                </MicroserviceCardTags>
                <MicroserviceCardActions>
                    {dockerhubHref && (
                        <MicroserviceCardLinkButton
                            href={dockerhubHref}
                            destination="dockerhub"
                        />
                    )}
                    {githubHref && (
                        <MicroserviceCardLinkButton
                            href={githubHref}
                            destination="github"
                        />
                    )}
                </MicroserviceCardActions>
            </MicroserviceCardFooter>
        </MicroserviceCardContainer>
    )
}

const filterMicroservicesData =
    (selectedFilters: SelectedMicroservicesFilters) =>
    (microservice: Microservice) => {
        const { type, framework, serving, hardware } = microservice
        const typeFilterValues = selectedFilters.type.map(({ value }) => value)
        const frameworkFilterValues = selectedFilters.framework.map(
            ({ value }) => value
        )
        const servingFilterValues = selectedFilters.serving.map(
            ({ value }) => value
        )
        const hardwareFilterValues = selectedFilters.hardware.map(
            ({ value }) => value
        )
        const isTypeFilterMatch =
            typeFilterValues.length === 0 || typeFilterValues.includes(type)
        const isFrameworkFilterMatch =
            frameworkFilterValues.length === 0 ||
            (framework !== null && frameworkFilterValues.includes(framework))
        const isServingFilterMatch =
            servingFilterValues.length === 0 ||
            (serving !== null &&
                serving.some((s) => servingFilterValues.includes(s)))
        const isHardwareFilterMatch =
            hardwareFilterValues.length === 0 ||
            (hardware !== null &&
                hardware.some((h) => hardwareFilterValues.includes(h)))

        return (
            isTypeFilterMatch &&
            isFrameworkFilterMatch &&
            isServingFilterMatch &&
            isHardwareFilterMatch
        )
    }

interface MicroservicesCardsProps {
    selectedFilters: SelectedMicroservicesFilters
    anyFiltersSelected: boolean
    onCardTagClick: (
        filterType: keyof SelectedMicroservicesFilters,
        tag: string
    ) => void
}

const MicroservicesCards = ({
    selectedFilters,
    anyFiltersSelected,
    onCardTagClick,
}: MicroservicesCardsProps) => {
    const microservicesData = useAppSelector(microservicesDataSelector)
    const microservicesLoadingState = useAppSelector(
        microservicesLoadingStateSelector
    )

    const microservicesCards = () => {
        let microservicesCardsData = microservicesData
        if (anyFiltersSelected) {
            microservicesCardsData = microservicesData.filter(
                filterMicroservicesData(selectedFilters)
            )
        }

        if (microservicesCardsData.length === 0) {
            return (
                <NoMicroservicesFoundMessage>
                    <p>No microservices found</p>
                    <p>Please try different filters</p>
                </NoMicroservicesFoundMessage>
            )
        }

        return microservicesCardsData.map((microservice, index) => (
            <MicroserviceCard
                key={`microservice-card-${index}`}
                {...microservice}
                selectedFilters={selectedFilters}
                onCardTagClick={onCardTagClick}
            />
        ))
    }

    return (
        <MicroservicesCardsGrid>
            {microservicesLoadingState === LoadingState.Pending ? (
                <SpinnerContainer />
            ) : (
                microservicesCards()
            )}
        </MicroservicesCardsGrid>
    )
}

export default MicroservicesCards
