/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import 'bootstrap/dist/css/bootstrap.min.css'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import './fonts/IntelClearPro_W_Bd.woff'
import './fonts/intelone-display-light.woff2'
import './fonts/intelone-display-regular.woff'
import './fonts/intelone-text-regular.woff'
import './index.css'
import reportWebVitals from './reportWebVitals'
import store from './store/store'
import { defaultTheme } from './themes/defaultTheme/theme'

import { UAParser } from 'ua-parser-js'
import AccessMgmtContainer from './containers/accessMgmtContainer/accessMgmtContainer'
import AudioContainer from './containers/audioContainer'
import DashboardContainer from './containers/dashboardContainer/dashboardContainer'
import DiscoverContainer from './containers/discoverContainer'
import LoginCallBackContainer from './containers/loginCallBackContainer/loginCallBackContainer'
import MarketingContainer from './containers/marketingContainer'
import MicroservicesContainer from './containers/microservicesContainer'
import PageNotFoundContainer from './containers/pageNotFoundContainer'
import ReasoningContainer from './containers/reasoningContainer'
import RetrievalContainer from './containers/retrievalContainer'
import SummarizationContainer from './containers/summarizationContainer'
import TranslationContainer from './containers/translationContainer'
import VisualContainer from './containers/visualContainer'
import SolutionLayout from './layouts/solutionLayout/solutionLayout'
import UnSupportedBrowserError from './layouts/unSupportedBrowserError'
import StringUtils from './lib/stringUtils'
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

console.log('v0.0')
let ugerAgent = navigator.userAgent
let isChromeBrowser: boolean = true
let isAllowedOtherBrowser: boolean = false
const ua = new UAParser(ugerAgent)
let browserName = ua.getBrowser().name
if (
    browserName !== undefined &&
    !(browserName == 'Chrome' || browserName == 'Edge')
) {
    isChromeBrowser = false
}
let allowOtherBrowser = localStorage.getItem('isAllowedOtherBrowser')
if (!StringUtils.isNullOrUndefinedOrEmpty(allowOtherBrowser)) {
    isAllowedOtherBrowser = true
}
root.render(
    // <React.StrictMode>
    <ThemeProvider theme={defaultTheme}>
        <Provider store={store}>
            <BrowserRouter>
                <Routes>
                    <Route
                        index
                        element={
                            isChromeBrowser || isAllowedOtherBrowser ? (
                                <MarketingContainer />
                            ) : (
                                <UnSupportedBrowserError />
                            )
                        }
                    />
                    <Route
                        path="/login/callback"
                        element={<LoginCallBackContainer />}
                    />
                    <Route path="/home" element={<MarketingContainer />} />
                    <Route path="/explore" element={<DiscoverContainer />} />
                    <Route
                        path="/explore/chat"
                        element={<DiscoverContainer />}
                    />
                    {/* <Route
                        path="/explore/chat/page/:modelId"
                        element={<DiscoverContainer />}
                    /> */}
                    <Route
                        path="/explore/retrieval"
                        element={<RetrievalContainer />}
                    />
                    <Route
                        path="/explore/visual"
                        element={<VisualContainer />}
                    />
                    {/* <Route
                        path="/explore/compare"
                        element={<CompareContainer />}
                    /> */}
                    <Route
                        path="/explore/summarization"
                        element={<SummarizationContainer />}
                    />
                    <Route
                        path="/explore/translation"
                        element={<TranslationContainer />}
                    />
                    <Route
                        path="/explore/reasoning"
                        element={<ReasoningContainer />}
                    />
                    <Route path="/explore/audio" element={<AudioContainer />} />
                    {/* <Route
                        path="/explore/deploy"
                        element={<ComposeContainer />}
                    /> */}
                    <Route
                        path="/explore/microservices"
                        element={<MicroservicesContainer />}
                    />
                    <Route
                        path="/solution/:solution"
                        element={<SolutionLayout />}
                    />
                    <Route
                        path="/explore/page/:modelId"
                        element={<DiscoverContainer />}
                    />
                    <Route
                        path="/explore/reasoning/page/:modelId"
                        element={<ReasoningContainer />}
                    />
                    <Route path="/dashboard" element={<DashboardContainer />} />
                    <Route path="/access" element={<AccessMgmtContainer />} />

                    <Route path="*" element={<PageNotFoundContainer />} />
                </Routes>
            </BrowserRouter>
        </Provider>
    </ThemeProvider>
    // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
