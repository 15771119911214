import { Icon } from '@iconify/react'
import { MouseEvent, useEffect, useRef, useState } from 'react'
import Select, {
    ClearIndicatorProps,
    components,
    OptionProps,
    Props,
    ValueContainerProps,
} from 'react-select'
import styled from 'styled-components'

export type MicroserviceFilterOption = {
    readonly value: string
    readonly label: string
}

const OptionContainer = styled.span`
    display: grid;
    grid-template-columns: 1rem 1fr;
    align-items: center;
    gap: 0.5rem;
`

const ValueContainerWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0.75rem;
`
const ValueContainerCount = styled.span`
    font-weight: 500;
    color: ${(props) => props.theme.color.text.navy};
`

const ClearIndicator = (
    props: ClearIndicatorProps<MicroserviceFilterOption>
) => (
    <components.ClearIndicator {...props}>
        <Icon icon="mdi:close" fontSize={16} />
    </components.ClearIndicator>
)

const Option = (props: OptionProps<MicroserviceFilterOption>) => (
    <components.Option {...props}>
        <OptionContainer>
            <Icon
                icon="mdi:check"
                fontSize={16}
                color={props.isSelected ? '#0068b5' : 'darkgray'}
            />
            <span>{props.label}</span>
        </OptionContainer>
    </components.Option>
)

const ValueContainer = ({
    children,
    ...props
}: ValueContainerProps<MicroserviceFilterOption, true>) => {
    const {
        getValue,
        selectProps: { placeholder, onMenuOpen },
    } = props
    const selectedOptions = getValue()
    const count = selectedOptions.length

    const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault()
        onMenuOpen()
    }

    return (
        <components.ValueContainer {...props}>
            <ValueContainerWrapper onMouseDown={handleMouseDown}>
                <span>{placeholder}</span>
                <ValueContainerCount>
                    {count > 0 && `(${count})`}
                </ValueContainerCount>
            </ValueContainerWrapper>
        </components.ValueContainer>
    )
}

const MicroserviceFilterSelect = (
    props: Props<MicroserviceFilterOption, true>
) => {
    const [isSelectMenuListOpen, setIsSelectMenuListOpen] = useState(false)
    const selectRef = useRef<HTMLDivElement>(null)

    const handleClickOutside: EventListenerOrEventListenerObject = (event) => {
        if (
            selectRef.current &&
            !selectRef.current.contains(event.target as Node)
        ) {
            setIsSelectMenuListOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <div ref={selectRef}>
            <Select
                {...props}
                isMulti
                isClearable
                openMenuOnClick
                openMenuOnFocus
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                    ValueContainer,
                    Option,
                    ClearIndicator,
                }}
                menuIsOpen={isSelectMenuListOpen}
                onMenuOpen={() => setIsSelectMenuListOpen(true)}
                onMenuClose={() => setIsSelectMenuListOpen(false)}
                styles={{
                    clearIndicator: (base) => ({
                        ...base,
                        cursor: 'pointer',
                    }),
                    control: (base, state) => ({
                        ...base,
                        width: '220px',
                        fontSize: '14px',
                        cursor: 'pointer',
                        padding: 0,
                        margin: 0,
                        borderRadius: 0,
                        borderColor: state.menuIsOpen ? '#0068B5' : '#d8e3e7',
                        ':hover': {
                            borderColor: '#0068B5',
                        },
                    }),
                    menu: (base) => ({
                        ...base,
                        width: 'max-content',
                        minWidth: '100%',
                        fontSize: '14px',
                        paddingBlock: 0,
                        borderRadius: 0,
                    }),
                    menuList: (base) => ({
                        ...base,
                        paddingBlock: 0,
                        borderRadius: 0,
                    }),
                    option: (provided, state) => ({
                        ...provided,
                        color: 'black',
                        cursor: 'pointer',
                        borderRadius: 0,
                        backgroundColor: state.isFocused
                            ? '#f0f0f0'
                            : 'transparent',
                        ':active': {
                            backgroundColor: 'transparent',
                        },
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        padding: 0,
                        width: '100%',
                        height: '38px',
                    }),
                }}
            />
        </div>
    )
}

export default MicroserviceFilterSelect
