import { useEffect, useState } from 'react'
import styled from 'styled-components'
import MicroservicesFilters, {
    defaultFiltersState,
    MicroserviceFilterSelectChangeHandler,
    SelectedMicroservicesFilters,
} from '../components/microservicesCardsFilters'
import MicroservicesFiltersTags, {
    FilterTagClickHandler,
} from '../components/microservicesFiltersTags'
import { useAppDispatch } from '../config/hooks'
import ContainerWrapper from '../layouts/containerWrapper'
import MicroservicesCards from '../layouts/microservicesCards'
import { getMicroservices } from '../store/microservices.slice'
import { ContainerHeadline } from './_styledContainers'

const MicroservicesDescription = styled.p`
    margin-top: 1rem;
`

const MicroservicesContainer = () => {
    const [selectedFilters, setSelectedFilters] =
        useState<SelectedMicroservicesFilters>(defaultFiltersState)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getMicroservices())
    }, [])

    const handleFilterChange =
        (
            name: keyof SelectedMicroservicesFilters
        ): MicroserviceFilterSelectChangeHandler =>
        (newValue) => {
            setSelectedFilters((prevFilters) => ({
                ...prevFilters,
                [name]: newValue,
            }))
        }

    const clearFilters = () => {
        setSelectedFilters(defaultFiltersState)
    }

    const anyFiltersSelected = Object.values(selectedFilters).some(
        (values) => values.length > 0
    )

    const handleFilterTagClick: FilterTagClickHandler = (name, option) => {
        setSelectedFilters((prevFilters) => ({
            ...prevFilters,
            [name]: prevFilters[name].filter(
                (prevOption) =>
                    prevOption.value !== option.value &&
                    prevOption.label !== option.label
            ),
        }))
    }

    const handleCardTagClick = (
        filterType: keyof SelectedMicroservicesFilters,
        tag: string
    ) => {
        const isClickedTagSelected = selectedFilters[filterType].every(
            (filterOption) => filterOption.value !== tag
        )

        if (isClickedTagSelected) {
            setSelectedFilters((prevFilters) => ({
                ...prevFilters,
                [filterType]: [
                    ...prevFilters[filterType],
                    { value: tag, label: tag },
                ],
            }))
        } else {
            setSelectedFilters((prevFilters) => ({
                ...prevFilters,
                [filterType]: prevFilters[filterType].filter(
                    (prevOption) =>
                        prevOption.value !== tag && prevOption.label !== tag
                ),
            }))
        }
    }

    return (
        <ContainerWrapper>
            <ContainerHeadline>Microservices</ContainerHeadline>
            <MicroservicesDescription>
                Microservices are akin to building blocks, offering the
                fundamental services for constructing RAG (Retrieval-Augmented
                Generation) and other Enterprise AI applications. Each
                Microservice is designed to perform a specific function or task
                within the application architecture. Microservices promote
                modularity, flexibility, and scalability by breaking down the
                system into smaller, self-contained services.
            </MicroservicesDescription>
            <MicroservicesFilters
                selectedFilters={selectedFilters}
                onFilterChange={handleFilterChange}
            />
            <MicroservicesFiltersTags
                selectedFilters={selectedFilters}
                onFilterTagClick={handleFilterTagClick}
                onClearFiltersClick={clearFilters}
            />
            <MicroservicesCards
                selectedFilters={selectedFilters}
                anyFiltersSelected={anyFiltersSelected}
                onCardTagClick={handleCardTagClick}
            />
        </ContainerWrapper>
    )
}

export default MicroservicesContainer
