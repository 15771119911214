import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { LoadingState } from '../models/loadingState'
import MicroservicesService from '../services/microservicesService'
import { MicroservicesData } from '../types/microservices'
import { RootState } from './reducer'

interface MicroservicesState {
    data: MicroservicesData
    loadingState: LoadingState
    error: any
}

const initialState: MicroservicesState = {
    data: { microservices: [] },
    loadingState: LoadingState.Idle,
    error: null,
}

export const getMicroservices = createAsyncThunk(
    'microservices/getMicroservices',
    async () => await MicroservicesService.getMicroservices()
)

const microservicesSlice = createSlice({
    name: 'microservices',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getMicroservices.pending, (state, _) => {
                if (state.loadingState === LoadingState.Idle) {
                    state.loadingState = LoadingState.Pending
                }
            })
            .addCase(getMicroservices.fulfilled, (state, action: any) => {
                if (state.loadingState === LoadingState.Pending) {
                    state.loadingState = LoadingState.Idle
                    state.data = action.payload.data
                    state.error = null
                }
            })
            .addCase(getMicroservices.rejected, (state, action: any) => {
                if (state.loadingState === LoadingState.Pending) {
                    state.loadingState = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const microservicesDataSelector = (state: RootState) =>
    state.microservicesReducer.data.microservices
export const microservicesLoadingStateSelector = (state: RootState) =>
    state.microservicesReducer.loadingState
export const microservicesDataErrorSelector = (state: RootState) =>
    state.microservicesReducer.error
export const microservicesReducer = microservicesSlice.reducer
