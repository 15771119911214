/************************************************
 * Copyright (C) 2021 Intel Corporation
 ************************************************/
import 'aws-amplify/auth/enable-oauth-listener'
import { Hub } from 'aws-amplify/utils'
import { FunctionComponent } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import stringUtils from '../../lib/stringUtils'

export interface ILoginCallBackContainerProps {}

console.log('hubevent logincallback')

const LoginCallBackContainer: FunctionComponent<ILoginCallBackContainerProps> & {
    defaultProps: Partial<ILoginCallBackContainerProps>
} = ({}: ILoginCallBackContainerProps) => {
    let navigate = useNavigate()
    let invitationKey = localStorage.getItem('invitationKey')

    Hub.listen('auth', (data) => {
        const { payload } = data

        console.log('A new auth event has happened: ', data.payload.event)
        console.log(payload.event)

        switch (payload.event) {
            case 'signInWithRedirect':
            case 'signedIn':
                localStorage.setItem('SSOLogin', 'true')
                if (!stringUtils.isNullOrUndefinedOrEmpty(invitationKey)) {
                    navigate('/invitation')
                } else {
                    navigate('/')
                }
                break
            case 'signInWithRedirect_failure':
                console.log('Error', data)
                break
        }
    })

    return <div>Login Successful</div>
}

LoginCallBackContainer.defaultProps = {}

const mapDispatchToProps = () => {
    return {}
}

const mapStateToProps = () => {
    return {}
}

type StateToPropsType = ReturnType<typeof mapStateToProps>
type DispatchToPropsType = typeof mapDispatchToProps

export default connect<StateToPropsType, DispatchToPropsType>(
    mapStateToProps,
    mapDispatchToProps
)(LoginCallBackContainer)
