import { ActionMeta, MultiValue } from 'react-select'
import styled from 'styled-components'
import { useAppSelector } from '../config/hooks'
import { LoadingState } from '../models/loadingState'
import {
    microservicesDataSelector,
    microservicesLoadingStateSelector,
} from '../store/microservices.slice'
import MicroserviceFilterSelect, {
    MicroserviceFilterOption,
} from './microserviceFilterSelect'

const MicroservicesFiltersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
`

const typeOptions: MicroserviceFilterOption[] = [
    { value: 'Embedding', label: 'Embedding' },
    { value: 'Retriever', label: 'Retriever' },
    { value: 'Reranking', label: 'Reranking' },
    { value: 'ASR', label: 'ASR' },
    { value: 'TTS', label: 'TTS' },
    { value: 'Dataprep', label: 'Dataprep' },
    { value: 'LLM', label: 'LLM' },
]

const frameworkOptions: MicroserviceFilterOption[] = [
    { value: 'LangChain/LlamaIndex', label: 'LangChain/LlamaIndex' },
    { value: 'Qdrant', label: 'Qdrant' },
    { value: 'Redis', label: 'Redis' },
]

const servingOptions: MicroserviceFilterOption[] = [
    {
        value: 'TEI-Gaudi',
        label: 'TEI-Gaudi',
    },
    {
        value: 'TEI',
        label: 'TEI',
    },
    {
        value: 'TGI Gaudi',
        label: 'TGI Gaudi',
    },
    {
        value: 'TGI',
        label: 'TGI',
    },
    {
        value: 'Ray Serve',
        label: 'Ray Serve',
    },
    {
        value: 'vLLM',
        label: 'vLLM',
    },
]

const hardwareOptions: MicroserviceFilterOption[] = [
    {
        value: 'Gaudi2',
        label: 'Gaudi2',
    },
    {
        value: 'Xeon',
        label: 'Xeon',
    },
]

export interface SelectedMicroservicesFilters {
    type: MultiValue<MicroserviceFilterOption>
    framework: MultiValue<MicroserviceFilterOption>
    serving: MultiValue<MicroserviceFilterOption>
    hardware: MultiValue<MicroserviceFilterOption>
}

export const defaultFiltersState: SelectedMicroservicesFilters = {
    type: [],
    framework: [],
    serving: [],
    hardware: [],
}

export type MicroserviceFilterSelectChangeHandler = (
    newValue: MultiValue<MicroserviceFilterOption>,
    actionMeta: ActionMeta<MicroserviceFilterOption>
) => void

interface MicroservicesFiltersProps {
    selectedFilters: SelectedMicroservicesFilters
    onFilterChange: (
        name: keyof SelectedMicroservicesFilters
    ) => MicroserviceFilterSelectChangeHandler
}

const MicroservicesFilters = ({
    selectedFilters,
    onFilterChange,
}: MicroservicesFiltersProps) => {
    const microservicesLoadingState = useAppSelector(
        microservicesLoadingStateSelector
    )
    const microservicesData = useAppSelector(microservicesDataSelector)

    const filtersDisabled =
        microservicesLoadingState === LoadingState.Pending ||
        microservicesData.length === 0

    return (
        <MicroservicesFiltersContainer>
            <MicroserviceFilterSelect
                value={selectedFilters.type}
                placeholder="Type"
                options={typeOptions}
                isDisabled={filtersDisabled}
                onChange={onFilterChange('type')}
            />
            <MicroserviceFilterSelect
                value={selectedFilters.framework}
                placeholder="Framework"
                options={frameworkOptions}
                isDisabled={filtersDisabled}
                onChange={onFilterChange('framework')}
            />
            <MicroserviceFilterSelect
                value={selectedFilters.serving}
                placeholder="Serving"
                options={servingOptions}
                isDisabled={filtersDisabled}
                onChange={onFilterChange('serving')}
            />
            <MicroserviceFilterSelect
                value={selectedFilters.hardware}
                placeholder="Hardware"
                options={hardwareOptions}
                isDisabled={filtersDisabled}
                onChange={onFilterChange('hardware')}
            />
        </MicroservicesFiltersContainer>
    )
}

export default MicroservicesFilters
